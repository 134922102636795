
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
// import SignUpPage from './components/SignUpPage';
// import TermsOfService from './component/TermsOfService'; 

import Home from './home';
// import TermsOfService from './components/TermsOfService';
// import PrivacyPolicyPage from './components/PrivacyPolicyPage';
// import OtpVerification from './components/OtpVerfication';
// import LoginPage from './components/LoginPage';


function App() {
  return (
      <Router>
        <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/auth/:id" element={<LoginPage />} /> */}
          {/* <Route path="/singup" element={<SignUpPage />} /> */}
          {/* <Route path="/terms" element={<TermsOfService />} /> */}
          {/* <Route path="/privacy" element={<PrivacyPolicyPage />} /> */}
          {/* <Route path="/otp-verification" element={<OtpVerification />} /> */}
        </Routes>
      </Router>
  );
}

export default App;



