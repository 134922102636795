import React from 'react';
import { Card, Button } from 'react-bootstrap';

const GetAppCard = () => {
  return (
    <div className="position-fixed bottom-0 end-0 p-3">
      <Card className="text-center shadow-lg" style={{ width: '150px', height:"180px", borderRadius: '10px', backgroundColor: ' #d4f553' }}>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title className="text-dark mb-0" style={{ fontSize: '14px', fontWeight: 'bold' }}>
              Get the app
            </Card.Title>
            <Button
              variant="link"
              className="text-dark p-0"
              style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'none' }}
              onClick={() => console.log('Close button clicked')}
            >
              &times;
            </Button>
          </div>
          <div className="mt-3">
            <img
              src="./assest/QR.png" // Replace with the actual QR code image
              alt="QR Code"
              className="img-fluid"
              style={{ width: '100px', height: '100px' }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default GetAppCard;
