// import React from "react";
// import { Container } from "react-bootstrap";

// const ImageContainer = () => {
//   return (
//     <Container
//       fluid
//       className="d-flex justify-content-center align-items-center pt-5"
//       style={{ backgroundColor: "#0f0f0f" , height:"50vh" , paddingTop:"200px"}}
//     >
//       <img
//         src="./assest/phone2.webp" // Replace with the path to your image
//         alt="Descriptive Alt Text"
//         className="img-fluid"
//         style={{
//         //   maxWidth: "100%",
//           height: "70vh",
//           borderRadius: "10px",
//           zIndex:"",
//           position:"absolute"
//         }}
//       />
//     </Container>
//   );
// };

// export default ImageContainer;




import React from "react";
import { Container } from "react-bootstrap";

const ImageContainer = () => {
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center pt-5"
      style={{
        backgroundColor: "#0f0f0f",
        height: "50vh",
        paddingTop: "200px",
        position: "relative", 
      }}
    >
      <img
        src="./assest/favicon3.jpeg" 
        alt="Descriptive Alt Text"
        className="img-fluid"
        style={{
          height: "70vh", 
          maxWidth: "90%", 
          objectFit: "contain", 
          borderRadius: "10px",
          position: "absolute", 
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)", 
        }}
      />
    </Container>
  );
};

export default ImageContainer;
