// import React, { useState, useRef } from 'react';
// import { Link } from "react-router-dom";
// import { Navbar, Nav, Button, Container } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// function CustomNavbar() {
//   const [hoveredMenu, setHoveredMenu] = useState(null); // Tracks which menu is open
//   const closeTimeoutRef = useRef(null); // Ref to store the timeout for closing dropdown

//   const handleMouseEnter = (menu) => {
//     clearTimeout(closeTimeoutRef.current); // Clear any existing timeout
//     setHoveredMenu(menu); // Open the hovered menu
//   };

//   const handleMouseLeave = () => {
//     closeTimeoutRef.current = setTimeout(() => {
//       setHoveredMenu(null);
//     }, 200);
//   };

//   return (
//     <>
//       <Navbar expand="lg" variant="light" className="shadow-sm" style={{ background: '#0d2f66', marginBottom: '0px', position: 'relative' }} sticky="top">
//         <Container >
//           <Navbar.Brand href="#">
//             <img src="./assest/Eazype1.png" alt="Logo" style={{ width: '100px', height: 'auto' }} />
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
//             <Nav className="me-auto ms-5" style={{ fontSize: '1.1rem' }}>
//               <div
//                 className='mt-2'
//                 onMouseEnter={() => handleMouseEnter("products")}
//                 onClick={() => handleMouseEnter("products")}
//                 onMouseLeave={handleMouseLeave}
//                 style={{ position: 'relative', cursor: 'pointer' }}
//               >
//                 <span className="text-white mx-2">Products</span>

//               </div>

//               <div
//                 className='mt-2'
//                 onMouseEnter={() => handleMouseEnter("solutions")}
//                 onClick={() => handleMouseEnter("solutions")}
//                 onMouseLeave={handleMouseLeave}
//                 style={{ position: 'relative', cursor: 'pointer' }}
//               >
//                 <span className="text-white mx-3 ">Solutions</span>

//               </div>

//               <Nav.Link href="#partners" className="mx-2 text-white">Partners</Nav.Link>
//               <Nav.Link href="#company" className="mx-2 text-white">Company</Nav.Link>
//               <Nav.Link href="#resources" className="mx-2 text-white">Resources</Nav.Link>
//               <Nav.Link href="#pricing" className="mx-2 text-white">Pricing</Nav.Link>
//             </Nav>

//             <Nav>
//               <Nav.Link href="#contact" className="mx-2 text-white" style={{ fontSize: '1.1rem' }}>Contact Sales</Nav.Link>

//               <Link to="/auth/login">
//                 <Button

//                   className="me-3 px-3 w-auto d-inline-block w-sm-auto bg-white"
//                   style={{
//                     borderRadius: '50px',
//                     color: '#0d2f66',
//                     fontSize: '1.1rem',
//                     borderColor: "#4e2c75",
//                   }}
//                 >
//                   Login
//                 </Button>
//               </Link>
//               <Link to="/auth/singup">
//                 <Button
//                   className="px-4 bg-white"
//                   style={{ borderRadius: '50px', color: '#0d2f66', fontSize: '1.1rem', border: 'none' }}
//                 >
//                   Registration
//                 </Button>

//               </Link>
//             </Nav>

//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//       {hoveredMenu === "products" && (
//         <div
//           style={dropdownStyle}
//           onMouseEnter={() => handleMouseEnter("products")}
//           onMouseLeave={handleMouseLeave}
//         >
//           <Container style={dropdownContentStyle}>
//             <div style={dropdownColumnStyle}>
//               <Nav.Link href="#pay">Pay</Nav.Link>
//               <Nav.Link href="#electricity">Electricity</Nav.Link>
//               <Nav.Link href="#gas-piped">Gas-Piped</Nav.Link>
//               <Nav.Link href="#lpg-gasline">LPG Gasline</Nav.Link>
//               <Nav.Link href="#water">Water</Nav.Link>
//               <Nav.Link href="#municipal-taxes-services">Municipal Taxes & Services</Nav.Link>
//             </div>
//             <div style={dropdownColumnStyle}>
//               <Nav.Link href="#mobile-postpaid-landline-postpaid">Mobile Postpaid / Landline Postpaid</Nav.Link>
//               <Nav.Link href="#mobile-prepaid-recharges">Mobile Prepaid Recharges</Nav.Link>
//               <Nav.Link href="#broadband-datacard">Broadband / Datacard</Nav.Link>
//               <Nav.Link href="#cable">Cable</Nav.Link>
//               <Nav.Link href="#dth">DTH</Nav.Link>
//             </div>
//           </Container>
//         </div>
//       )}
//       {hoveredMenu === "solutions" && (
//         <div
//           style={dropdownStyle}
//           onMouseEnter={() => handleMouseEnter("solutions")}
//           onMouseLeave={handleMouseLeave}
//         >
//           <Container style={dropdownContentStyle}>
//             <div style={dropdownColumnStyle}>
//               <p>OVERVIEW</p>
//               <Nav.Link href="#small-businesses">Small Businesses</Nav.Link>
//               <Nav.Link href="#startups">Startups</Nav.Link>
//               <Nav.Link href="#smes">SMEs</Nav.Link>
//               <Nav.Link href="#enterprises">Enterprises</Nav.Link>
//             </div>
//             <div style={dropdownColumnStyle}>
//               <p>BY INDUSTRY</p>
//               <Nav.Link href="#retail-ecommerce">Retail & Ecommerce</Nav.Link>
//               <Nav.Link href="#manufacturers">Manufacturers</Nav.Link>
//               <Nav.Link href="#real-estates">Real Estates</Nav.Link>
//               <Nav.Link href="#software-technology">Software & Technology</Nav.Link>
//             </div>
//             <div style={dropdownColumnStyle}>
//               <Nav.Link href="#healthcare">Healthcare</Nav.Link>
//               <Nav.Link href="#hospitality">Hospitality</Nav.Link>
//               <Nav.Link href="#professional-services">Professional Services</Nav.Link>
//               <Nav.Link href="#consultants-freelancers">Consultants & Freelancers</Nav.Link>
//             </div>
//           </Container>
//         </div>
//       )}
//     </>
//   );
// }
// const dropdownStyle = {
//   position: 'absolute',
//   left: 0,
//   width: '100%',
//   backgroundColor: 'white',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   zIndex: 9999,
//   padding: '20px 0',
//   display: 'flex',
//   justifyContent: 'center',

// };

// const dropdownContentStyle = {
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   width: "auto"
// };

// const dropdownColumnStyle = {
//   flex: 1,
//   padding: '0 20px',
// };
// export default CustomNavbar;

// import React from "react";
// import { Navbar, Nav, Button, Container, Row, Col,  Dropdown  } from "react-bootstrap";

// const Header = () => {
//   return (
//     <Container fluid  className="bg-dark">
//       <Navbar bg="dark" variant="dark" expand="lg" className="px-5">

//         <Navbar.Brand href="#" className="navbar-img">
//           {/* <img src="./assest/coinswap.png" alt="paxfulLogo" /> */}
//           <img src="./assest/coinswap.png" alt="paxfulLogo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto ms-5">
//             {/* <Nav.Link href="#marketplace">Marketplace</Nav.Link> */}

//             <Nav.Link href="#wallet">Wallet</Nav.Link>
//             {/* <Nav.Link href="#company">Company</Nav.Link> */}
//             <Dropdown as={Nav.Item}>
//               <Dropdown.Toggle as={Nav.Link} id="dropdown-company" >
//                 Company
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 <Dropdown.Item href="#about">About</Dropdown.Item>
//                 <Dropdown.Item href="#reviews">Reviews</Dropdown.Item>
//                 <Dropdown.Item href="#careers">Careers</Dropdown.Item>
//                 <Dropdown.Item href="#support">Support</Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>
//             <Nav.Link href="#learn">Learn</Nav.Link>
//           </Nav>
//           <div className="s-button">
//             <Button variant="outline-light" className="me-4 signin-button">
//               Sign in
//             </Button>
//             <Button variant="outline-light" className="me-5 signup-button">Sign up</Button>
//           </div>
//         </Navbar.Collapse>
//       </Navbar>

//       <Navbar variant="dark" className="navbar-bg">
//       <Container>
//         <Row className="justify-content-center align-items-center text-center w-100">
//           <Col md={8}>
//             <a
//               href="https://www.example.com"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="header-link"
//             >
//               Get the crypto trading app reviewers call <strong>"the one to beat in P2P"</strong>
//               <br />
//               Download on iOS and Android
//             </a>
//           </Col>
//         </Row>
//       </Container>
//     </Navbar>
//     </Container>
//   );
// };

// export default Header;

// import React from "react";
// import { Navbar, Nav, Button, Container, Dropdown, Row, Col } from "react-bootstrap";

// const CustomNavbar = () => {
//   return (
//     <Container fluid className="bg-dark">
//       <Navbar bg="dark" variant="dark" expand="lg" className="px-5">
//         <Navbar.Brand href="#" className="navbar-img">
//           <img src="./assest/coinswap.png" alt="paxfulLogo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto ms-5">
//             {/* Dropdown for Marketplace */}
//             <Dropdown as={Nav.Item}>
//               <Dropdown.Toggle as={Nav.Link} id="dropdown-marketplace" className="text-white">
//                 Marketplace
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 <Dropdown.Item href="#buy">Buy Crypto</Dropdown.Item>
//                 <Dropdown.Item href="#sell">Sell Crypto</Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>

//             <Nav.Link href="#wallet">Wallet</Nav.Link>

//             {/* Dropdown for Company */}
//             <Dropdown as={Nav.Item}>
//               <Dropdown.Toggle as={Nav.Link} id="dropdown-company" className="text-white">
//                 Company
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 <Dropdown.Item href="#about">About</Dropdown.Item>
//                 <Dropdown.Item href="#reviews">Reviews</Dropdown.Item>
//                 <Dropdown.Item href="#careers">Careers</Dropdown.Item>
//                 <Dropdown.Item href="#support">Support</Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>

//             <Nav.Link href="#learn">Learn</Nav.Link>
//           </Nav>
//           <div className="s-button">
//             <Button variant="outline-light" className="me-4 signin-button">
//               Sign in
//             </Button>
//             <Button variant="outline-light" className="me-5 signup-button">
//               Sign up
//             </Button>
//           </div>
//         </Navbar.Collapse>
//       </Navbar>

//       <Navbar variant="dark" className="navbar-bg">
//         <Container>
//           <Row className="justify-content-center align-items-center text-center w-100">
//             <Col md={8}>
//               <a
//                 href="https://www.example.com"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="header-link"
//               >
//                 Get the crypto trading app reviewers call <strong>"the one to beat in P2P"</strong>
//                 <br />
//                 Download on iOS and Android
//               </a>
//             </Col>
//           </Row>
//         </Container>
//       </Navbar>
//     </Container>
//   );
// };

// export default CustomNavbar;

// import React, { useState } from "react";
// import { Navbar, Nav, Button, Container, Dropdown, Row, Col } from "react-bootstrap";

// const Header = () => {
//   // State to control the hover effect for each dropdown
//   const [showMarketplace, setShowMarketplace] = useState(false);
//   const [showCompany, setShowCompany] = useState(false);

//   return (
//     <Container fluid className="bg-dark">
//       <Navbar bg="dark" variant="dark" expand="lg" className="px-5">
//         <Navbar.Brand href="#" className="navbar-img">
//           <img src="./assest/coinswap.png" alt="paxfulLogo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto ms-5 gap-3">
//             {/* Marketplace Dropdown with Hover */}
//             <Dropdown
//               as={Nav.Item}
//               onMouseEnter={() => setShowMarketplace(true)}
//               onMouseLeave={() => setShowMarketplace(false)}
//               show={showMarketplace}
//             >
//               <Dropdown.Toggle as={Nav.Link} id="dropdown-marketplace" className="text-white">
//                 Marketplace
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 <Dropdown.Item href="#buy">Buy Crypto</Dropdown.Item>
//                 <Dropdown.Item href="#sell">Sell Crypto</Dropdown.Item>
//                 <Dropdown.Item href="#offers">View Offers</Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>

//             <Nav.Link href="#wallet">Wallet</Nav.Link>

//             {/* Company Dropdown with Hover */}
//             <Dropdown
//               as={Nav.Item}
//               onMouseEnter={() => setShowCompany(true)}
//               onMouseLeave={() => setShowCompany(false)}
//               show={showCompany}
//             >
//               <Dropdown.Toggle as={Nav.Link} id="dropdown-company" className="text-white">
//                 Company
//               </Dropdown.Toggle>
//               <Dropdown.Menu>
//                 <Dropdown.Item href="#about">About</Dropdown.Item>
//                 <Dropdown.Item href="#reviews">Reviews</Dropdown.Item>
//                 <Dropdown.Item href="#careers">Careers</Dropdown.Item>
//                 <Dropdown.Item href="#support">Support</Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown>

//             <Nav.Link href="#learn">Learn</Nav.Link>
//           </Nav>
//           <div className="s-button">
//             <Button variant="outline-light" className="me-4 signin-button">
//               Sign in
//             </Button>
//             <Button variant="outline-light" className="me-5 signup-button">
//               Sign up
//             </Button>
//           </div>
//         </Navbar.Collapse>
//       </Navbar>

//       <Navbar variant="dark" className="navbar-bg">
//         <Container>
//           <Row className="justify-content-center align-items-center text-center w-100">
//             <Col md={8}>
//               <a
//                 href="https://www.example.com"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="header-link"
//               >
//                 Get the crypto trading app reviewers call <strong>"the one to beat in P2P"</strong>
//                 <br />
//                 Download on iOS and Android
//               </a>
//             </Col>
//           </Row>
//         </Container>
//       </Navbar>
//     </Container>
//   );
// };

// export default Header;

import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";

const CustomNavbar = () => {
  const [showMarketplace, setShowMarketplace] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  return (
    <Container fluid className="bg-dark">
      <Navbar bg="black" variant="dark" expand="lg" className="px-5 ">
        <Navbar.Brand href="#" className="navbar-img">
          <img src="./assest/coinswap.png" alt="paxfulLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-5 gap-4">
            {/* Marketplace Dropdown with Hover */}
            <Dropdown
              as={Nav.Item}
              onMouseEnter={() => setShowMarketplace(true)}
              onMouseLeave={() => setShowMarketplace(false)}
              show={showMarketplace}
            >
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-marketplace"
                className="text-white"
              >
                Marketplace
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item href="#buy" className="custom-dropdown-item">
                  Buy Crypto
                </Dropdown.Item>
                <Dropdown.Item href="#sell" className="custom-dropdown-item">
                  Sell Crypto
                </Dropdown.Item>
                <Dropdown.Item href="#offers" className="custom-dropdown-item">
                  View Offers
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link href="#wallet">Wallet</Nav.Link>

            {/* Company Dropdown with Hover */}
            <Dropdown
              as={Nav.Item}
              onMouseEnter={() => setShowCompany(true)}
              onMouseLeave={() => setShowCompany(false)}
              show={showCompany}
            >
              <Dropdown.Toggle
                as={Nav.Link}
                id="dropdown-company"
                className="text-white"
              >
                Company
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item href="#about" className="custom-dropdown-item">
                  About
                </Dropdown.Item>
                <Dropdown.Item href="#reviews" className="custom-dropdown-item">
                  Reviews
                </Dropdown.Item>
                <Dropdown.Item href="#careers" className="custom-dropdown-item">
                  Careers
                </Dropdown.Item>
                <Dropdown.Item href="#support" className="custom-dropdown-item">
                  Support
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link href="#learn">Learn</Nav.Link>
          </Nav>
          <div className="s-button">
            <Button variant="outline-light" className="me-4 signin-button">
              Sign in
            </Button>
            <Button variant="outline-light" className="me-5 signup-button">
              Sign up
            </Button>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Navbar variant="dark" className="navbar-bg">
        <Container>
          <Row className="justify-content-center align-items-center text-center w-100">
            <Col md={8}>
              <a
                href="https://www.example.com"
                target="_blank"
                rel="noopener noreferrer"
                className="header-link"
              >
                Get the crypto trading app reviewers call{" "}
                <strong>"the one to beat in P2P"</strong>
                <br />
                Download on iOS and Android
              </a>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </Container>
  );
};

export default CustomNavbar;
