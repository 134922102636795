// import React from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import { motion } from "framer-motion";

// const FeaturesSection = () => {
//     // Array containing different images, titles, and descriptions for each card
//     const features = [
//         {
//             title: "Trade worldwide",
//             description:
//                 "Buy and sell local and digital currencies including Bitcoin, Ethereum, Tether, and USDC – across 140 markets with 500+ payment methods.",
//             icon: "./assest/animation.svg",
//         },
//         {
//             title: "Send money instantly",
//             description:
//                 "Send cash or cryptocurrency to anyone, anytime – with faster, cheaper, and simpler transactions powered by the blockchain.",
//             icon: "./assest/moneyt.svg",
//         },
//         {
//             title: "Supply global markets",
//             description:
//                 "Become a peer-to-peer market maker and benefit from arbitrage trading opportunities across regions and payment methods.",
//             icon: "./assest/supply.svg",
//         },
//         {
//             title: "Access the globe",
//             description:
//                 "Rely on Paxful to overcome local infrastructure constraints and access the global economy, even without a bank account.",
//             icon: "./assest/globe.svg",
//         },
//         {
//             title: "Escape volatility",
//             description:
//                 "Swap high-inflating fiat currencies for digital stores of value like BTC or stablecoins to hedge against inflation or protect against banking issues.",
//             icon: "./assest/escape.svg",
//         },
//         {
//             title: "Scale your business",
//             description:
//                 "Leverage Paxful’s liquidity, market reach, payment diversification, and security features to rapidly expand your business to new heights.",
//             icon: "./assest/scalebusiness.svg",
//         },
//     ];

//     return (
//         <Container
//             fluid
//             className="py-5 text-center text-light"
//             style={{ backgroundColor: "#0f0f0f" }}
//         >
//             <h1 className="mb-5">The world's largest P2P marketplace</h1>
//             <Row className="g-4" style={{marginLeft:"100px", marginRight:"100px"}}>
//                 {features.map((feature, index) => (
//                     <Col md={4} sm={6} key={index}>
//                         <div className="feature-card text-center p-4">
//                             {/* Animated Image */}
//                             <motion.img
//                                 src={feature.icon}
//                                 alt={feature.title}
//                                 className="mb-3"
//                                 style={{ width: "60px", height: "60px" }}
//                                 initial={{ opacity: 0, scale: 0.8 }}
//                                 animate={{ opacity: 1, scale: 1 }}
//                                 transition={{
//                                     duration: 0.5,
//                                     delay: index * 0.2, // Adds staggered animation
//                                 }}
//                             />
//                             {/* Unique Title */}
//                             <h5 className="mb-2">{feature.title}</h5>
//                             {/* Unique Description */}
//                             <p className="">{feature.description}</p>
//                         </div>
//                     </Col>
//                 ))}
//             </Row>
//         </Container>
//     );
// };

// export default FeaturesSection;


import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

const FeaturesSection = () => {
    // Array containing different images, titles, and descriptions for each card
    const features = [
        {
            title: "Trade worldwide",
            description:
                "Buy and sell local and digital currencies including Bitcoin, Ethereum, Tether, and USDC – across 140 markets with 500+ payment methods.",
            icon: "./assest/animation.svg",
        },
        {
            title: "Send money instantly",
            description:
                "Send cash or cryptocurrency to anyone, anytime – with faster, cheaper, and simpler transactions powered by the blockchain.",
            icon: "./assest/moneyt.svg",
        },
        {
            title: "Supply global markets",
            description:
                "Become a peer-to-peer market maker and benefit from arbitrage trading opportunities across regions and payment methods.",
            icon: "./assest/supply.svg",
        },
        {
            title: "Access the globe",
            description:
                "Rely on Paxful to overcome local infrastructure constraints and access the global economy, even without a bank account.",
            icon: "./assest/globe.svg",
        },
        {
            title: "Escape volatility",
            description:
                "Swap high-inflating fiat currencies for digital stores of value like BTC or stablecoins to hedge against inflation or protect against banking issues.",
            icon: "./assest/escape.svg",
        },
        {
            title: "Scale your business",
            description:
                "Leverage Paxful’s liquidity, market reach, payment diversification, and security features to rapidly expand your business to new heights.",
            icon: "./assest/scalebusiness.svg",
        },
    ];

    return (
        <Container
            fluid
            className="py-5 text-center text-light"
            style={{ backgroundColor: "black" }}
        >
            <h1 className="mb-5">The world's largest P2P marketplace</h1>
            <Row className="g-4">
                {features.map((feature, index) => (
                    <Col xs={12} md={4} key={index}>
                        <div className="feature-card text-center p-4">
                            <motion.img
                                src={feature.icon}
                                alt={feature.title}
                                className="mb-3"
                                style={{ width: "60px", height: "60px" }}
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                    duration: 0.5,
                                    delay: index * 0.2,
                                }}
                            />
                            <h5 className="mb-2">{feature.title}</h5>
                            <p className="feature-description text-center">
                                {feature.description}
                            </p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default FeaturesSection;

