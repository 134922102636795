import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const LogoSection = () => {
  const companies = [
    { name: "Business Insider", logo: "./assest/logo1.svg" },
    { name: "Forbes", logo: "./assest/logo2.svg" },
    { name: "Time 100", logo: "./assest/logo3.svg" },
    { name: "Yahoo Finance", logo: "./assest/logo4.svg" },
    { name: "CoinDesk", logo: "./assest/logo5.svg" },
  ];

  return (
    <Container
      fluid
      className="bg-white py-5"  
    >
      <Row className="justify-content-center">
        {companies.map((company, index) => (
          <Col
            xs={6}    
            sm={4}    
            md={2}    
            key={index}
            className="d-flex justify-content-center mb-4"  
          >
            <Image
              src={company.logo}
              alt={company.name}
              fluid 
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default LogoSection;
