
// import React from 'react';
// import { Container, Row, Col, Button } from 'react-bootstrap';
// import { backgroundColor } from '../constent';

// function HeroSection() {
//     return (
//         <Container fluid className="text-center my-5 px-4" style={{ zIndex: 0 }}>
//             <Row>
//                 <Col xs={12} md={10} lg={8} className="mx-auto">
//                     <h1
//                         className="fw-bold mt-4 fs-1 fs-md-2 fs-lg-3"
//                     >
//                         Simplify Business Payments<br className="d-none d-md-block" />
//                         with Connected Banking
//                     </h1>
//                     <p
//                         className="text-muted mt-4 fs-5"
//                     >
//                         Simplify your finances and stay on top of cashflow with Eazy Pe. Connect bank accounts<br className="d-none d-lg-block" />
//                         to pay vendors, receive payments, and reconcile effortlessly.
//                     </p>

//                     <Button
//                         variant="primary"
//                         size="lg"
//                         className="px-4 py-3 fw-bold"
//                         style={{
//                             borderRadius: '50px',
//                             color: '#fcf9f9',
//                             backgroundColor:`${backgroundColor}`,
//                             fontSize: '1.1rem',
//                             border: 'none',
//                             marginTop:'25px'
//                         }}
//                     >
//                         Get Started for Free
//                     </Button>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default HeroSection;




import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

const HeroSection = () => {
    return (
        <Container fluid className="hero-section text-center text-light py-5 bg-black">
            <Container> 
                <Row className="align-items-center mt-5 ms-5">
                    {/* Left Column: Content */}
                    <Col md={6} className="text-center text-md-start my-auto hero-section">
                        <h1 className="display-4 fw-bold">
                            The <span className="hero-section">user-powered <br></br>solution</span> way <br></br>for moving <br></br> money
                           
                        </h1>
                        <p className="lead mt-4">
                            The largest P2P marketplace to buy, sell, send, and <br></br> receive crypto
                            and local currencies.
                        </p>

                        {/* Email Signup Form */}
                        <div className="formfield">
                            <Form className="d-flex justify-content-center justify-content-md-start mt-4 ">
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email here..."
                                    className="rounded-start"
                                    style={{
                                        maxWidth: "300px",
                                        backgroundColor: "black",
                                        color: "white",
                                        border: "1px solid #9cc63b",
                                        borderRight: "none",
                                        borderRadius: "25px 0 0 25px",
                                    }}
                                />
                                <Button
                                    className="rounded-end"
                                    style={{
                                        backgroundColor: "#9cc63b",
                                        color: "black",
                                        border: "none",
                                        borderRadius: "0 25px 25px 0",
                                        padding: "0.5rem 1.5rem",
                                    }}
                                >
                                    Sign up now
                                </Button>
                            </Form>
                        </div>
                        {/* Download Links */}
                        <p className="mt-3 hero-section">
                            Download on <a href="#" className="hero-section">iOS</a> and <a href="#" className="hero-section">Android</a>
                        </p>
                    </Col>

                    {/* Right Column: Image or Graphics */}
                    <Col md={6} className="mt-4 mt-md-0">
                        <div className="hero-graphic d-flex justify-content-center">
                            <img
                                src="./assest/circle.svg"
                                alt="Global users map"
                                className="img-fluid rounded"
                                style={{ maxHeight: "400px" }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

        </Container>
    );
};

export default HeroSection;





