import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Footer() {
  return (
    <div
      style={{
        backgroundColor: "black",
        color: "#FFFFFF",
        padding: "50px 20px",
      }}
    >
      <Container>
        <Row className="g-3">
          {/* Column 1: About Us */}

          <Col xs={12} sm={6} md={2} className="mb-4">
            <h6>Trade crypto</h6>
            <ul
              style={{ fontSize: "0.8rem", listStyle: "none", paddingLeft: 0 }}
            >
              <li>Buy Bitcoin</li>
              <li>Buy Ethereum</li>
              <li>Buy Tether</li>
              <li>Buy USDC</li>
            </ul>
          </Col>

          {/* Column 2: Products */}
          <Col xs={12} sm={6} md={2} className="mb-4">
            <h6>Trade crypto</h6>
            <ul
              style={{ fontSize: "0.8rem", listStyle: "none", paddingLeft: 0 }}
            >
              <li>sell Bitcoin</li>
              <li>sell Ethereum</li>
              <li>sell Tether</li>
              <li>sell USDC</li>
            </ul>
          </Col>

          {/* Column 3: Solutions */}
          <Col xs={12} sm={6} md={2} className="mb-4">
            <h6>About paxful</h6>
            <ul
              style={{ fontSize: "0.8rem", listStyle: "none", paddingLeft: 0 }}
            >
              <li> About us</li>
              <li>Careers</li>
              <li>Blog</li>
              <li>Reviews</li>
            </ul>
          </Col>

          {/* Column 4: Resources */}
          <Col xs={12} sm={6} md={2} className="mb-4">
            <h6>Useful links</h6>
            <ul
              style={{ fontSize: "0.8rem", listStyle: "none", paddingLeft: 0 }}
            >
              <li>Paxful Wallet</li>
              <li>Support</li>
              <li>Bitcoin Calculator</li>
              <li>Peer-to-Peer Market Prices</li>
            </ul>
          </Col>

          {/* Column 5: Contact Us */}
          <Col xs={12} sm={6} md={4} className="mb-4">
            <h6>Legal</h6>
            <ul
              style={{ fontSize: "0.8rem", listStyle: "none", paddingLeft: 0 }}
            >
              <li> Terms & Conditions</li>
              <li>Vendor Reminder</li>
              <li>AML Policy</li>
              <li>Stablecoin Terms Of Service</li>
              <li>Privacy Notice</li>
              <li>Cookie Policy</li>
              <li>Restricted Countries</li>
              <li>VIP Program T&C</li>
            </ul>
          </Col>
        </Row>
        <hr className="border-0 border-top border-white" />
        <p className="text-center mt-3">
          All rights reserved © WEB TECH SOLUTIONS FZ - LLC 
        </p>
      </Container>
    </div>
  );
}

export default Footer;

// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

// function Footer() {
//   return (
//     <div style={{ backgroundColor: '#001A35', color: '#FFFFFF', padding: '50px 20px' }}>
//       <Container>
//         <Row className="g-4 footer">
//           {/* Column 1: Trade Crypto */}
//           <Col xs={12} sm={6} md={4} lg={2} className="text-center text-lg-start">
//             <h6>Trade crypto</h6>
//             <ul style={{ fontSize: '0.8rem', listStyle: 'none', paddingLeft: 0 }}>
//               <li>Buy Bitcoin</li>
//               <li>Buy Ethereum</li>
//               <li>Buy Tether</li>
//               <li>Buy USDC</li>
//             </ul>
//           </Col>

//           {/* Column 2: Sell Crypto */}
//           <Col xs={12} sm={6} md={4} lg={2} className="text-center text-lg-start">
//             <h6>Sell crypto</h6>
//             <ul style={{ fontSize: '0.8rem', listStyle: 'none', paddingLeft: 0 }}>
//               <li>Sell Bitcoin</li>
//               <li>Sell Ethereum</li>
//               <li>Sell Tether</li>
//               <li>Sell USDC</li>
//             </ul>
//           </Col>

//           {/* Column 3: About Paxful */}
//           <Col xs={12} sm={6} md={4} lg={2} className="text-center text-lg-start">
//             <h6>About Paxful</h6>
//             <ul style={{ fontSize: '0.8rem', listStyle: 'none', paddingLeft: 0 }}>
//               <li>About us</li>
//               <li>Careers</li>
//               <li>Blog</li>
//               <li>Reviews</li>
//             </ul>
//           </Col>

//           {/* Column 4: Useful Links */}
//           <Col xs={12} sm={6} md={6} lg={2} className="text-center text-lg-start">
//             <h6>Useful Links</h6>
//             <ul style={{ fontSize: '0.8rem', listStyle: 'none', paddingLeft: 0 }}>
//               <li>Paxful Wallet</li>
//               <li>Support</li>
//               <li>Bitcoin Calculator</li>
//               <li>Peer-to-Peer Market Prices</li>
//             </ul>
//           </Col>

//           {/* Column 5: Legal */}
//           <Col xs={12} sm={12} md={6} lg={4} className="text-center text-lg-start">
//             <h6>Legal</h6>
//             <ul style={{ fontSize: '0.8rem', listStyle: 'none', paddingLeft: 0 }}>
//               <li>Terms & Conditions</li>
//               <li>Vendor Reminder</li>
//               <li>AML Policy</li>
//               <li>Stablecoin Terms Of Service</li>
//               <li>Privacy Notice</li>
//               <li>Cookie Policy</li>
//               <li>Restricted Countries</li>
//               <li>VIP Program T&C</li>
//             </ul>
//           </Col>
//         </Row>

//         <hr className="border-0 border-top border-white" />

//         {/* Footer Bottom */}
//         <p className="text-center mt-3" style={{ fontSize: '0.9rem' }}>
//           All rights reserved © WEB TECH SOLUTIONS FZ - LLC
//         </p>
//       </Container>
//     </div>
//   );
// }

// export default Footer;
