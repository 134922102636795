import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";


const Starttrading = () => {
  return (
    <div className="page-container">
      <Container>
        {/* Main Content */}
        <Row className="justify-content-center mt-5">
          <Col md={8}>
            <h1 className="fw-bold fs-2">Start trading on Coinswap today</h1>
            <p className="mt-4 fs-9">
              Trade bitcoin, ethereum, tether and more with over 14 million
              global users
            </p>
          </Col>
        </Row>

        {/* Trustpilot Section */}
        <Row className="justify-content-center">
          <Col md={4} className="d-flex justify-content-center mt-3">
            <img
            //   src="https://upload.wikimedia.org/wikipedia/commons/6/63/Trustpilot-logo.png"
              src="./assest/image.png"
              alt="Trustpilot Logo"
              className="trustpilot-logo"
            />
          </Col>
        </Row>

        {/* QR Code Section */}
        <Row className="justify-content-center mt-3">
          <Col md={3} className="d-flex justify-content-center">
            <img
              src="./assest/QR.png"
              alt="QR Code"
              className="qr-code" style={{height:"150px", width:"160px"}}
            />
          </Col>
        </Row>

        {/* Signup Button */}
        <Row className="justify-content-center mt-4">
          <Col md={4} className="d-flex justify-content-center">
            <Button className="sign-up-button" variant="dark" size="lg">
              Sign up now
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Starttrading;
