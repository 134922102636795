// import React from "react";
// import { Container, Row, Col, Button, Card } from "react-bootstrap";

// const UserStories = () => {
//     return (
//         <Container fluid style={{ backgroundColor: "#000", color: "#fff", padding: "120px 200px" }}>
//             <Row className="align-items-center">
//                 {/* Left Section */}
//                 <Col
//                     xs={12}
//                     md={7}
//                     className="text-center text-md-start hero-section mb-4 mb-md-0 px-4 "
//                 >
//                     <h1>
//                         Real users.
//                         <br /> Real stories.
//                     </h1>
//                     <p className="mt-4">
//                         Learn how Paxful is changing the lives of <br /> thousands across the globe.
//                     </p>
//                     <Button

//                         className="fs-6 mt-3"
//                         style={{
//                             backgroundColor: "#B3F700",
//                             borderColor: "#B3F700",
//                             color: "#000",
//                             borderRadius: "50rem",

//                         }}
//                     >
//                         See all
//                     </Button>
//                 </Col>

//                 {/* Right Section */}
//                 <Col xs={12} md={5} className="d-flex justify-content-center">
//                     <Card
//                         style={{
//                             backgroundColor: "#000",
//                             border: "1px solid #B3F700",

//                             borderTopLeftRadius: "150px",
//                             borderTopRightRadius: "150px",

//                             width: "100%",
//                             maxWidth: "300px",
//                         }}
//                     >
//                         <Card.Img
//                             variant="top"
//                             src="./assest/ShubhamPoonia.jpg"
//                             style={{
//                                 borderRadius: "50%",
//                                 width: "150px",
//                                 height: "150px",
//                                 objectFit: "cover",
//                                 margin: "40px auto 50px",
//                             }}
//                         />
//                         <Card.Body className="text-center text-white">
//                             <Card.Title className="mb-4" >Shubham Poonia </Card.Title>
//                             <Card.Subtitle
//                                 className="mb-3  fs-10"
//                             >
//                                 C&nbsp;E&nbsp;O
//                             </Card.Subtitle>
//                             <Card.Text className="mb-5" style={{ fontSize: "0.9rem", color: "#fff" }}>
//                                 Trading Bitcoin has allowed him to earn an independent income and
//                                 reach goals in his life, "I now own a car, bought a piece of land,
//                                 and upgraded my business," he proudly shared.
//                             </Card.Text>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// };

// export default UserStories;


import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";

const UserStories = () => {
    return (
        <Container
            fluid
            style={{
                backgroundColor: "#000",
                color: "#fff",
                padding: "120px 15px", // Adjust padding to be more responsive
            }}
        >
            <Row className="align-items-center">
                {/* Left Section */}
                <Col
                    xs={12}
                    md={7}
                    className="text-center text-md-start hero-section mb-4 mb-md-0 px-4"
                >
                    <h1>
                        Real users.
                        <br /> Real stories.
                    </h1>
                    <p className="mt-4">
                        Learn how Paxful is changing the lives of <br /> thousands across the globe.
                    </p>
                    <Button
                        className="fs-6 mt-3"
                        style={{
                            backgroundColor: "#B3F700",
                            borderColor: "#B3F700",
                            color: "#000",
                            borderRadius: "50rem",
                        }}
                    >
                        See all
                    </Button>
                </Col>

                {/* Right Section */}
                <Col
                    xs={12}
                    md={5}
                    className="d-flex justify-content-center mt-4 mt-md-0"
                >
                    <Card
                        style={{
                            backgroundColor: "#000",
                            border: "1px solid #B3F700",
                            borderTopLeftRadius: "150px",
                            borderTopRightRadius: "150px",
                            width: "100%",
                            maxWidth: "300px",
                        }}
                    >
                        <Card.Img
                            variant="top"
                            src="./assest/ShubhamPoonia.jpg"
                            style={{
                                borderRadius: "50%",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                margin: "40px auto 50px",
                            }}
                        />
                        <Card.Body className="text-center text-white">
                            <Card.Title className="mb-4">Shubham Poonia</Card.Title>
                            <Card.Subtitle className="mb-3 fs-10">
                                Make Money
                            </Card.Subtitle>
                            <Card.Text className="mb-5" style={{ fontSize: "0.9rem", color: "#fff" }}>
                                Trading Bitcoin has allowed him to earn an independent income and
                                reach goals in his life. "I now own a car, bought a piece of land,
                                and upgraded my business," he proudly shared.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default UserStories;
