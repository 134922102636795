// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";

// const WalletSection = () => {
//   return (
//     <Container fluid className="py-5" style={{ backgroundColor: "#fff" }}>
//       <Row className="align-items-center mt-5">
//         {/* Left Column - Images */}
//         <Col md={6} className="text-center">
//           <div className="position-relative">
//             <img
//               // src="./assest/Phone1.webp"
//               src="./assest/Phone-n1.jpeg"
//               alt="Phone 1"
//               className="img"
//               style={{
//                 height:"100vh",

//                 transform: "rotate(-10deg)",
//               }}
//             />
//           </div>
//         </Col>

//         <Col md={6} className="text-md-start " style={{
//           marginBottom:"60vh"
//         }}> 
//           <h3 className="mb-4 display-6 fw-bold">
//             ✈️ Send money instantly – no
//             <br />
//             bank account required
//           </h3>
//           <p className="mb-4">
//             Send, store, and receive BTC, ETH, USDT, and more in your free and
//             secure
//             <br />
//             Paxful cryptocurrency wallet.
//           </p>
//           <Button className="getwalletbutton">
//             Get your wallet
//           </Button>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default WalletSection;







import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";


const WalletSection = () => {
  return (
    <Container fluid className="py-5 wallet-section bg-light">
      <Row className="align-items-center">
        {/* Left Column - Images */}
        <Col xs={12} md={6} className="d-flex justify-content-center mb-4 mb-md-0">
          <div className="position-relative">
            <img
              src="./assest/Phone-n1.jpeg"
              
              alt="Phone"
              className="phone-image img-fluid"
            />
          </div>
        </Col>

        {/* Right Column - Text */}
        <Col xs={12} md={6} className="d-flex flex-column align-items-center align-items-md-start text-center text-md-start">
          <h3 className="mb-4 display-6 fw-bold">
            ✈️ Send money instantly – no
            <br />
            bank account required
          </h3>
          <p className="mb-4">
            Send, store, and receive BTC, ETH, USDT, and more in your free and
            secure Paxful cryptocurrency wallet.
          </p>
          <Button className="getwalletbutton">Get your wallet</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default WalletSection;

















// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";

// const WalletSection = () => {
//   return (
//     <Container fluid className="py-5" style={{ backgroundColor: "#fff" }}>
//       <Row className="align-items-center mt-5">
//         {/* Left Column - Images */}
//         <Col md={6} className="text-center">
//           <div className="position-relative">
//             <img
//               // src="./assest/Phone1.webp"
//               src="./assest/Phone-n.png"
//               alt="Phone 1"
//               className="img"
//               style={{
//                 height:"100vh",
                  
//                 transform: "rotate(-5deg)",
//               }}
//             />
//           </div>
//         </Col>

//         <Col md={5} className="text-md-start " style={{
//           marginBottom:"60vh"
//         }}> 
//           <h3 className="mb-4 display-6 fw-bold">
//             ✈️ Send money instantly – no
//             <br />
//             bank account required
//           </h3>
//           <p className="mb-4">
//             Send, store, and receive BTC, ETH, USDT, and more in your free and
//             secure
//             <br />
//             Paxful cryptocurrency wallet.
//           </p>
//           <Button className="getwalletbutton">
//             Get your wallet
//           </Button>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default WalletSection;
