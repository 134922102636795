import React from 'react'
import "./App.css";
import CustomNavbar from './components/CustomNavbar';
import HeroSection from './components/HeroSection';


// import Mpay from './components/Mpay';
// import VideoSection from './components/VideoSection';
// import Partners from './components/partners';
// import SecuritySection from './components/SecuritySection';
// import BusinessPayments from './components/BusinessPayments';
// import PaymentsPage from './components/PaymentsPage';
// import Payments from './components/Payments';
// import ConnectBanking from './components/ConnectBanking';



import Footer from './components/Footer';
import WalletSection from './components/WalletSection';
import MarketplaceFeatures from './components/MarketplaceFeatures';
import ImageContainer from './components/ImageContainer';
import Starttrading from './components/starttrading';
import UserStories from './components/UserStories';
import LogoSection from './components/LogoSection';
import GetAppCard from './components/GetAppCard';






function home() {
  return (
    <div>
      <CustomNavbar />
      <GetAppCard />
      <HeroSection />
      <LogoSection />
      <WalletSection />
      <MarketplaceFeatures />
      <UserStories />
      <ImageContainer />
      <Starttrading />
      
   
      {/* <Mpay /> */}
      {/* <SecuritySection /> */}
  
  
   
      <Footer />
    </div>
  )
}

export default home;
